import { client } from './client';

export const getAllEnv = async () => {
  const response = await client.get('/env');
  return response.data;
}

export const updateEnv = async (body: string) => {
  const response = await client.post('/env', body);
  return response.data;
}

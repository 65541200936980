import { Card } from 'react-bootstrap';

interface Props {
  className?: string,
  children: any,
}

export const Widget: React.FC<Props> = ({ className, children }) => (
  <Card className={`mb-3 ${className}`}>
    {children}
  </Card>
)
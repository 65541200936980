import React, { useState } from 'react';
import { Form } from 'react-bootstrap';

interface ToggleSwitchProps {
  isActive: boolean;
  onToggle: (newState: boolean) => void;
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({ isActive, onToggle }) => {
  const [isChecked, setIsChecked] = useState(isActive);

  const handleToggle = () => {
    const newState = !isChecked;
    setIsChecked(newState);
    onToggle(newState); // Вызываем функцию для обновления на сервере
  };

  return (
    <Form.Check
      type='switch'
      checked={isChecked}
      onChange={handleToggle}
    />
  );
};

export default ToggleSwitch;

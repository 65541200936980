import { getChatMessages, updateChatStatus } from '../api/chats';
import { Column } from '../types/Column';

export const privateChatColumns: Column[] = [
  {
    id: 1,
    name: 'ID',
    key: 'id',
  },
  {
    id: 2,
    name: 'First Name',
    key: 'first_name',
  },
  {
    id: 3,
    name: 'Last Name',
    key: 'last_name',
  },
  {
    id: 4,
    name: 'Username',
    key: 'username',
  },
  {
    id: 5,
    name: 'Type',
    key: 'type',
  },
  {
    id: 6,
    name: 'Bot Active',
    key: 'is_bot_active',
    toggleFunction: (chatId: number, newStatus: boolean) => {
      updateChatStatus(chatId, newStatus);
    },
  },
  // {
  //   id: 7,
  //   name: 'Recent Messages',
  //   key: 'recent_messages',
  //   getList: async (id: number) =>  {
  //     return await getChatMessages(id);
  //   }
  // }
];

import { Item } from '../types/Item';
import { client } from './client';

export const getKeywordsByTrigerDataId = async (trigerDataId: number) => {
  const response = await client.get(`/keyword/get/${trigerDataId}`);
  return response.data;
};

export const updateKeywords = async (object: { id: number, list: Item[] }) => {
  const response = await client.post('/keyword/update', object);
  return response.data;
};

import React, { useEffect, useState } from 'react';
import { getAllEnv, updateEnv } from '../api/env';
import { Button, Form, InputGroup, Modal } from 'react-bootstrap';
import { Widget } from '../components/Widget';
import { AutoResizeTextarea } from '../components/AutoResizeTextarea';

interface Env {
  key: string;
  value: string;
}

const Settings: React.FC = () => {
  const [envVars, setEnvVars] = useState({});
  const [newKey, setNewKey] = useState('');
  const [newValue, setNewValue] = useState('');
  const [modal, setModal] = useState<'save' | 'create' | null>(null);

  const loadEnv = async () => {
    const envObj = await getAllEnv();

    setEnvVars(envObj);
  }

  const handleChange = (key: string, value: string) => {
    setEnvVars({ ...envVars, [key]: value });
  };

  const handleSave = async () => {
    await updateEnv(JSON.stringify(envVars));
    await loadEnv();
    setModal(null);
  };

  const handleAdd = () => {
    if (newKey && newValue) {
        setEnvVars({ ...envVars, [newKey]: newValue });
        setNewKey('');
        setNewValue('');
        setModal(null);
    }
  };

  useEffect(() => {
    loadEnv();
  }, []);

  return (
    <>
      <Widget className='p-3'>
        <div className='d-flex align-items-center justify-content-between mb-3'>
          <h2 className='h3 m-0 text-secondary'>Env</h2>

          <div className='d-flex gap-2'>
            <Button variant='success' onClick={() => setModal('create')}>
              Creat New
            </Button>

            <Button
              onClick={() => setModal('save')}
            >
              Save
            </Button>
          </div>
        </div>

        <ul>
          {Object.entries(envVars).map(([key, value]: any) => (
            <li className='list-unstyled p-0 m-0' key={key}>
              <InputGroup className='mb-3'>
                <InputGroup.Text id={key}>{key}</InputGroup.Text>
                <AutoResizeTextarea
                  placeholder={key}
                  value={value}
                  onChange={(e) => handleChange(key, e.target.value)}
                />
              </InputGroup>
            </li>
          ))}
        </ul>
      </Widget>

      <Modal
        show={modal === 'save'}
        onHide={() => setModal(null)}
        style={{ zIndex: 99999 }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            You sure?
          </Modal.Title>
        </Modal.Header>

        <Modal.Footer>
          <Button
            variant='secondary'
            onClick={() => setModal(null)}
          >
            Cancel
          </Button>

          <Button
            variant='danger'
            onClick={handleSave}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={modal === 'create'}
        onHide={() => setModal(null)}
        style={{ zIndex: 99999 }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Create New
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className='d-flex flex-column gap-2'>
          <Form.Control
            placeholder='KEY'
            value={newKey}
            onChange={(e) => setNewKey(e.target.value)}
          />

          <Form.Control
            placeholder='VALUE'
            value={newValue}
            onChange={(e) => setNewValue(e.target.value)}
          />
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant='danger'
            onClick={() => setModal(null)}
          >
            Cancel
          </Button>

          <Button
            onClick={handleAdd}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
};

export default Settings;

import React from 'react';
import { Link } from 'react-router-dom';

const Sidebar: React.FC = () => {
  return (
    <div className='sidebar'>
      <Link to='/'>
        <h2>Quipster</h2>
      </Link>
      <ul>
        <li>
          <Link to='/account'>Account</Link>
        </li>
        <li>
          <Link to='/chats'>Chats</Link>
        </li>
        <li>
          <Link to='/quips'>Quips</Link>
        </li>
        <li>
          <Link to='/logs'>Logs</Link>
        </li>
        <li>
          <Link to='/settings'>Settings</Link>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;

import React, { useState, useEffect, useCallback } from 'react';
import TableView from '../components/TableView';
import ControlWidget from '../components/ControlWidget';
import { quipColumns } from '../constants/quipColumns';
import { exportQuips, getQuips, importQuips } from '../api/quip';
import { CustomPagination } from '../components/CustomPagination';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { actions, fetchQuips } from '../redux/features/quip/quipSlice';

const Quips = () => {
  const dispatch = useAppDispatch();
  const { quips, page, pageSize, count, status, keywordSearchTerm, quipSearchTerm } = useAppSelector(state => state.keyAnswer);

  const load = () => {
    dispatch(fetchQuips({ keywordSearchTerm, quipSearchTerm, page, pageSize }));
  };

  const pageChange = useCallback((page: number) => {
    dispatch(actions.setPage(page));
  }, [dispatch]);

  const handleExport = async () => {
    try {
      const object = { keywordSearchTerm, quipSearchTerm };
      const blob = await exportQuips(object);
  
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'export.xlsx';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Ошибка при экспорте файла:', error);
    }
  }

  useEffect(() => {
    load();
  }, [page, keywordSearchTerm, quipSearchTerm, pageSize, dispatch]);

  return (
    <>
        <ControlWidget
          handleImport={importQuips}
          handleExport={handleExport}
        />

      {/* {status === 'loading' ? (
        <Widget className='p-3'>
          <div>Loading...</div>
        </Widget>
      ) : status === 'failed' ? (
        <Widget className='p-3'>
          <div>Loading failed.</div>
        </Widget>
      ) : ( */}
        <TableView
          columns={quipColumns}
          data={quips}
        />
      {/* )} */}

      <CustomPagination page={page} countPages={Math.ceil(count / pageSize)} pageChange={pageChange} />
    </>
  );
};

export default Quips;

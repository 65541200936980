import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './pages/Home';
import Account from './pages/Account';
import Chats from './pages/Chats';
import Quips from './pages/Quips';
import Analytics from './pages/Analytics';
import Settings from './pages/Settings';
import Logs from './pages/Logs';

const App: React.FC = () => {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/account' element={<Account />} />
          <Route path='/chats' element={<Chats />} />
          <Route path='/quips' element={<Quips />} />
          <Route path='/logs' element={<Logs />} />
          <Route path='/settings' element={<Settings />} />
        </Routes>
      </Layout>
    </Router>
  );
};

export default App;

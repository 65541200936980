import { getKeywordsByTrigerDataId, updateKeywords } from '../api/keyword';
import { getQuipsByTrigerDataId, updateQuips } from '../api/quip';
import { Column } from '../types/Column';
import { Item } from '../types/Item';

export const quipColumns: Column[] = [
  {
    id: 1,
    name: 'ID',
    key: 'id',
  },
  {
    id: 2,
    name: 'Quips',
    key: 'quips',
    'second-key': 'text',
    getList: async (id) => {
      return await getQuipsByTrigerDataId(id);
    },
    generator: (id: number) => {
      return async (list: Item[]) => {
        return await updateQuips({ id, list });
      }
    }
  },
  {
    id: 3,
    name: 'Keywords',
    key: 'keywords',
    'second-key': 'text',
    getList: async (id) => {
      return await getKeywordsByTrigerDataId(id);
    },
    generator: (id: number) => {
      return async (list: Item[]) => {
        return await updateKeywords({ id, list });
      }
    }
  },
  {
    id: 4,
    name: 'Min Duplicate Count',
    key: 'minDuplicateCount',
  },
];

import React, { useState } from 'react';
import ToggleSwitch from './ToggleSwitch';
import { Column } from '../types/Column';
import { ModalDataList } from '../modals/ModalDataList';
import { ModalData } from '../types/ModalData';
import { Button, Modal } from 'react-bootstrap';
import { Widget } from './Widget';

interface TableViewProps {
  columns: Column[];
  data: any[];
}

const TableView: React.FC<TableViewProps> = ({ columns, data }) => {
  const [modalData, setModalData] = useState<ModalData | null>(null);

  const renderCell = (column: Column, row: any) => {
    const value = row[column.key];
    const empty = 'Empty';

    switch (typeof value) {
      case 'string':
      case 'number':
        return <td>{value || empty}</td>;

      case 'boolean':
        return (
          <td>
            <ToggleSwitch
              isActive={value}
              onToggle={(newState) => column.toggleFunction && column.toggleFunction(row.id, newState)}
            />
          </td>
        );

      case 'object':
        if (Array.isArray(value)) {
          const lastObj = value[value.length - 1];
          return (
            <td>
              <div className='data-table__cell'>
                <ul className='data-table__list'>
                  {value.map((obj) => (
                    <li className='data-table__text'>{(column['second-key']) ? obj[column['second-key']] : obj}</li>
                  )) || lastObj || empty}
                </ul>

                <Button
                  size='sm'
                  variant='outline-success'
                  className='border-0'
                  onClick={async () => {
                    if (column?.getList && column?.generator) {
                      const list = await column.getList(row.id);
                      const save = column.generator(row.id);

                      setModalData({ name: column.name, list, save });
                    }
                  }}
                >
                  <i className='bi bi-three-dots-vertical'></i>
                </Button>
              </div>
            </td>
          );
        }

        return <td>{empty}</td>;

      default:
        return <td>{empty}</td>;
    }
  };

  return (
    <Widget>
      <table className='data-table'>
        <thead>
          <tr>
            {columns.map((column) => (
              <th key={column.id}>{column.name}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row) => (
            <tr key={row.id}>
              {columns.map((column) => (
                <React.Fragment key={column.id}>
                  {renderCell(column, row)}
                </React.Fragment>
              ))}
            </tr>
          ))}
        </tbody>
      </table>


      <Modal
        show={!!modalData}
        onHide={() => setModalData(null)}
        style={{ zIndex: 99999 }}
      >
        <ModalDataList
          modalData={modalData}
          closeModal={() => setModalData(null)}
        />
      </Modal>
    </Widget>
  );
};

export default TableView;

import React from 'react';
import { TypeChat } from '../types/TypeChat';
import { ButtonGroup, ToggleButton } from 'react-bootstrap';
import { Widget } from './Widget';

interface ChatTypeSwitchProps {
  activeType: TypeChat;
  onTypeChange: (type: TypeChat) => void;
}

const ChatTypeSwitch: React.FC<ChatTypeSwitchProps> = ({ activeType, onTypeChange }) => {
  const chatValues: ['group', 'supergroup', 'channel', 'private'] = ['group', 'supergroup', 'channel', 'private'];

  return (
    <Widget>
      <ButtonGroup className='d-flex flex-row gap-1'>
        {chatValues.map((value) => (
          <ToggleButton
            key={value}
            id={`radio-${value}`}
            type='radio'
            name='radio'
            variant='success'
            value={value}
            checked={activeType === value}
            onChange={() => onTypeChange(value)}
          >
            {value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()}
          </ToggleButton>
        ))}
      </ButtonGroup>

    </Widget>
  );
};

export default ChatTypeSwitch;

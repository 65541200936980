import { Form, InputGroup } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Item } from '../types/Item';
import { useEffect, useState, useMemo } from 'react';
import { AutoResizeTextarea } from '../components/AutoResizeTextarea';
import { ModalData } from '../types/ModalData';

interface Props {
  modalData: ModalData | null;
  closeModal: () => void;
};

export const ModalDataList: React.FC<Props> = ({ modalData, closeModal }) => {
  const [list, setList] = useState<Item[]>([]);
  const [currentId, setCurrentId] = useState<number | null>(null);
  const [input, setInput] = useState('');
  const [error, setError] = useState('');

  const handleAddItem = () => {
    const id = Math.random();
    const newItem: Item = { id, text: '', type: 'create' };

    setList((list) => {
      list.unshift(newItem);
      return list;
    });

    startEdit(newItem);
  };

  const handlechange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (!e.target.value) {
      setError('This field is required.');
    } else {
      setError('');
    }

    setInput(e.target.value);
  };

  const startEdit = ({ id, text }: Item) => {
    canselEdit();
    setCurrentId(id);
    setInput(text || '');
  };

  const removeItem = (id: number) => {
    setList((list) => list.map((item) => (item.id !== id)
      ? item
      : { ...item, type: (item.type === 'create')
        ? 'ignor'
        : 'remove' }
    ));

    const actualItems = list.filter(({ type }) => type !== 'ignor' && type !== 'remove');

    if (actualItems.length < 1) {
      setError('This field is required.');
      return;
    }
  };

  const saveEdit = () => {
    if (!input) {
      setError('');
      return;
    }

    setList((list) => list.map((item) => (item.id !== currentId)
      ? item
      : { ...item, text: input, type: (!item.type && item.text !== input)
        ? 'update'
        : (input === '')
          ? (item.type === 'create')
            ? 'ignor'
            : 'remove'
          : item.type
        }
    ));
    canselEdit();
  };

  const canselEdit = () => {
    setCurrentId(null);
    setInput('');
  };

  const saveList = async () => {
    const result = list.filter(({ type }) => type !== 'ignor');

    if (modalData) {
      await modalData.save(result);
    }

    closeModal();
  }

  const actualItemsCount = useMemo(() => list.filter(({ type }) => type !== 'ignor' && type !== 'remove').length, list);

  useEffect(() => {
    setList(modalData?.list || []);
  }, []);

  return (
    <>
      <Modal.Header
        className='d-flex flex-row gap-2'
        closeButton
      >
        <Modal.Title>
          {modalData?.name}
        </Modal.Title>

        <Button
          variant='success'
          size='sm'
          onClick={handleAddItem}
        >
          Add
          <i className='bi bi-plus'></i>
        </Button>
      </Modal.Header>

      <Modal.Body className='d-flex flex-column gap-2'>
        {list.map(({ id, text, type }) => {
          if (type === 'remove' || type === 'ignor') {
            return;
          }

          return (
            <div key={id}>
              <InputGroup className='d-flex align-items-start me-2'>
                <AutoResizeTextarea
                  placeholder={modalData?.name || ''}
                  value={(id === currentId) ? input : text}
                  onChange={handlechange}
                  disabled={currentId !== id}
                  error={error}
                />

                {(currentId === id)
                  ? (
                  <>
                    <Button
                      variant='success'
                      onClick={saveEdit}
                      disabled={!!error}
                    >
                      <i className='bi bi-check-lg'></i>
                    </Button>
                    <Button
                      variant='danger'
                      onClick={canselEdit}
                    >
                      <i className='bi bi-x-lg'></i>
                    </Button>
                  </>
                  ) : (
                  <>
                    <Button
                      variant='primary'
                      onClick={() => startEdit({ id, text })}
                    >
                      <i className='bi bi-pen'></i>
                    </Button>

                    <Button
                      variant='danger'
                      onClick={() => removeItem(id)}
                      disabled={actualItemsCount <= 1}
                    >
                      <i className='bi bi-trash3'></i>
                    </Button>
                  </>
                  )
                }
              </InputGroup>
            </div>
          );
        })}
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={saveList}>Save</Button>
      </Modal.Footer>
    </>
  );
};

import React from 'react';
import { Card } from 'react-bootstrap';

const Account: React.FC = () => {
  return (
    <Card style={{ width: '100%' }}>
      <Card.Body>
        <Card.Title>Аккаунт</Card.Title>
      </Card.Body>
    </Card>
  );
};

export default Account;

import React from 'react';
import classNames from 'classnames';
import { Widget } from './Widget';
import { Pagination } from 'react-bootstrap';

interface Props {
  page: number;
  countPages: number;
  pageChange: (page: number) => void;
}

export const CustomPagination: React.FC<Props> = ({ page, countPages, pageChange }) => {
  const pages = Array.from({ length: countPages }, (_, index) => index + 1);

  return (
    <Widget className='p-3'>
      <Pagination className='d-flex justify-content-center'>
        <Pagination.Prev
          onClick={() => pageChange(page - 1)}
          disabled={page === 1}
        />

        {pages.map(pageNum => (
          <Pagination.Item
            key={pageNum}
            active={pageNum === page}
            onClick={() => pageChange(pageNum)}
          >
            {pageNum}
          </Pagination.Item>
        ))}

        <Pagination.Next
          onClick={() => pageChange(page + 1)}
          disabled={page === pages.length}
        />
      </Pagination>
    </Widget>
  );
}

import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createQuip, getQuips, removeQuip, updateQuips } from '../../../api/quip';
import { Quip } from '../../../types/Quip';
import { Item } from '../../../types/Item';
import { updateKeywords } from '../../../api/keyword';

type Status = 'idle' | 'loading' | 'succeeded' | 'failed';

interface QuipState {
  quips: Quip[];
  status: Status;
  error: string | null;
  page: number;
  pageSize: number;
  count: number;
  keywordSearchTerm: string;
  quipSearchTerm: string;
}

const initialState: QuipState = {
  quips: [],
  status: 'idle',
  error: null,
  page: 1,
  pageSize: 20,
  count: 0,
  keywordSearchTerm: '',
  quipSearchTerm: '',
};


export const fetchQuips = createAsyncThunk('keyAnswer/fetchKeyAnswers', async (params: { keywordSearchTerm: string, quipSearchTerm: string, page: number, pageSize: number }) => {
  return await getQuips(params);
});

export const addKeyAnswer = createAsyncThunk('keyAnswer/addKeyAnswer', async (createdObject: { keywords: string[], quips: string[], minDuplicateCount: number }) => {
  return await createQuip(createdObject);
});

export const editKeywords = createAsyncThunk('keyAnswer/editKeyword', async (updatedObject: { id: number, list: Item[] }) => {
  return await updateKeywords(updatedObject);
});

export const editAnswers = createAsyncThunk('keyAnswer/editAnswer', async (updatedObject: { id: number, list: Item[] }) => {
  return await updateQuips(updatedObject);
});

export const deleteKeyAnswer = createAsyncThunk('keyAnswer/deleteKeyAnswer', async (id: number) => {
  await removeQuip(id);
  return id;
});

const quipSlice = createSlice({
  name: 'quip',
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setSearchTerm: (state, action: PayloadAction<{ keywordSearchQuery: string, quipSearchQuery: string }>) => {
      state.keywordSearchTerm = action.payload.keywordSearchQuery;
      state.quipSearchTerm = action.payload.quipSearchQuery;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchQuips.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchQuips.fulfilled, (state, action: PayloadAction<{ rows: Quip[], count: number }>) => {
        state.status = 'succeeded';
        state.quips = action.payload.rows;
        state.count = action.payload.count;
      })
      .addCase(fetchQuips.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch data';
      })
      .addCase(addKeyAnswer.fulfilled, (state, action: PayloadAction<Quip>) => {
        state.quips.unshift(action.payload);
      })
      .addCase(editKeywords.fulfilled, (state, action: PayloadAction<Quip>) => {
        const index = state.quips.findIndex(quip => quip.id === action.payload.id);
        if (index !== -1) {
          state.quips[index] = action.payload;
        }
      })
      .addCase(editAnswers.fulfilled, (state, action: PayloadAction<Quip>) => {
        const index = state.quips.findIndex(quip => quip.id === action.payload.id);
        if (index !== -1) {
          state.quips[index] = action.payload;
        }
      })
      .addCase(deleteKeyAnswer.fulfilled, (state, action: PayloadAction<number>) => {
        state.quips = state.quips.filter(quip => quip.id !== action.payload);
      });
  },
});

export default quipSlice.reducer;
export const { actions } = quipSlice;

import { useEffect, useRef } from 'react';
import { Form } from 'react-bootstrap';

interface Props {
  placeholder: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  disabled?: boolean;
  error?: string;
}

export const AutoResizeTextarea: React.FC<Props> = ({ placeholder, value, onChange, disabled, error }) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleInput = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto'; // Сбрасываем высоту для пересчёта
      textarea.style.height = `${textarea.scrollHeight}px`; // Устанавливаем высоту по содержимому
    }
  };

  useEffect(() => {
    handleInput();
  }, [value, disabled]);

  return (
    <Form.Group controlId='auto-resize-textarea' className='flex-grow-1'>
      <Form.Control
        as='textarea'
        className='rounded-top-0 rounded-start-2'
        rows={1}
        ref={textareaRef}
        placeholder={placeholder}
        aria-label={placeholder}
        style={{ overflow: 'hidden', resize: 'none' }}
        value={value}
        onChange={onChange}
        disabled={disabled}
        isValid={!error}
        isInvalid={!!error}
        autoFocus
      />
      <Form.Control.Feedback type='invalid'>{error}</Form.Control.Feedback>
    </Form.Group>
    );
};

import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { actions } from '../redux/features/quip/quipSlice';
import { Widget } from './Widget';
import { Button, Container, Form, InputGroup, Spinner } from 'react-bootstrap';
import { ModalImport } from '../modals/ModalImport';
import { ModalCreateQuip } from '../modals/ModalCreateQuip';

interface ControlWidgetProps {
  handleImport?: (file: File) => Promise<any>;
  handleExport?: () => Promise<any>;
}

const ControlWidget: React.FC<ControlWidgetProps> = ({
  handleImport,
  handleExport,
}) => {
  const dispatch = useAppDispatch();
  const [keywordSearchQuery, setKeywordSearchQuery] = useState('');
  const [quipSearchQuery, setQuipSearchQuery] = useState('');
  const [isModal, setIsModal] = useState('');
  const { status } = useAppSelector(state => state.keyAnswer);

  const handleSearch = () => {
    dispatch(actions.setSearchTerm({ keywordSearchQuery, quipSearchQuery }));
  }

  const onCreateQuip = () => {
    setIsModal('create-quip');
  }

  const onModalImport = () => {
    setIsModal('import');
  }

  const handleEnter = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  }

  return (
    <Widget className='d-flex flex-row gap-2 p-3'>
      <InputGroup className='w-50'>
        <InputGroup.Text id='keyword-search' className='p-0'>
          {(status !== 'loading')
            ? <Button
                variant='light'
                className='rounded-0 rounded-start'
                onClick={handleSearch}
              >
                <i className='bi bi-search' />
              </Button>
            : <Spinner className='border-1' animation='border' size='sm' />
          }
        </InputGroup.Text>

        <Form.Control
          placeholder='Search By Keywords...'
          aria-label='Search By Keywords...'
          aria-describedby='keyword-search'
          value={keywordSearchQuery}
          onKeyDown={handleEnter}
          onChange={(e) => setKeywordSearchQuery(e.target.value)}
        />
      </InputGroup>

      <InputGroup className='w-50'>
        <InputGroup.Text id='quip-search' className='p-0'>
          {(status !== 'loading')
            ? <Button
                variant='light'
                className='rounded-0 rounded-start'
                onClick={handleSearch}
              >
                <i className='bi bi-search' />
              </Button>
            : <Spinner className='border-1' animation='border' size='sm' />
          }
        </InputGroup.Text>

        <Form.Control
          placeholder='Search By Quips...'
          aria-label='Search By Quips...'
          aria-describedby='quip-search'
          value={quipSearchQuery}
          onKeyDown={handleEnter}
          onChange={(e) => setQuipSearchQuery(e.target.value)}
        />
      </InputGroup>

      <Container className='d-flex flex-row justify-content-end gap-2'>
        <Button
          variant='success'
          onClick={onCreateQuip}
        >
          Create Quip
        </Button>

        {handleImport && (
          <Button onClick={onModalImport}>
            Import
          </Button>
        )}

        
        {handleExport && (
          <Button onClick={handleExport}>
            Export
          </Button>
        )}
      </Container>

      <ModalImport
        show={isModal === 'import'}
        onClose={() => setIsModal('')}
        handleFileUpload={handleImport!}
      />

      <ModalCreateQuip
        show={isModal === 'create-quip'}
        onClose={() => setIsModal('')}
      />
    </Widget>
  );
};

export default ControlWidget;

import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import * as formik from 'formik';
import * as yup from 'yup';
import { useAppDispatch } from '../redux/hooks';
import { addKeyAnswer } from '../redux/features/quip/quipSlice';

interface Error {
  keyword: string;
  answer: string;
}

const defaultError: Error = {
  keyword: '',
  answer: '',
}

const looksGood = 'Looks good!';
const required = 'This field is required.';

interface Props {
  show: boolean;
  onClose: () => void;
}

export const ModalCreateQuip: React.FC<Props> = ({ show, onClose }) => {
  const { Formik } = formik;
  const dispatch = useAppDispatch();

  const schema = yup.object().shape({
    keyword: yup.string().required('This field is required.'),
    answer: yup.string().required('This field is required.'),
  });


  const handleSubmit = async (values: { keyword: string, answer: string }) => {
    await dispatch(addKeyAnswer({ keywords: [values.keyword], quips: [values.answer], minDuplicateCount: 1 }));

    handleClose();
  }

  const handleClose = () => {
    onClose();
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      style={{ zIndex: 99999 }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Create Quip</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Formik
          validationSchema={schema}
          onSubmit={handleSubmit}
          initialValues={{
            keyword: '',
            answer: '',
          }}
        >
        {({ handleSubmit, handleChange, values, touched, errors }) => (
          <Form id='create-quip-form' noValidate onSubmit={handleSubmit}>
            <Form.Group className='mb-3'>
              <Form.Label>Keyword</Form.Label>
              <Form.Control
                type='text'
                name='keyword'
                placeholder='Enter keyword here...'
                value={values.keyword}
                onChange={handleChange}
                isValid={touched.keyword && !errors.keyword}
                isInvalid={!!errors.keyword}
              />
              <Form.Control.Feedback type='invalid'>{errors.keyword}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className='mb-3'>
              <Form.Label>Quip</Form.Label>
              <Form.Control
                as='textarea'
                name='answer'
                placeholder='Enter answer here...'
                rows={3}
                value={values.answer}
                onChange={handleChange}
                isValid={touched.answer && !errors.answer}
                isInvalid={!!errors.answer}
              />
              <Form.Control.Feedback type='invalid'>{errors.answer}</Form.Control.Feedback>
            </Form.Group>
          </Form>
        )}
      </Formik>
      </Modal.Body>

      <Modal.Footer>
        <Button variant='secondary' type='button' onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant='primary'
          type='submit'
          form='create-quip-form'
        >
          Create
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
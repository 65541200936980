import { Item } from '../types/Item';
import { client, clientFormData } from './client';

export const getQuipsByTrigerDataId = async (trigerDataId: number) => {
  const response = await client.get(`/quip/get/${trigerDataId}`);
  return response.data;
};

export const getQuips = async (object: { keywordSearchTerm: string, quipSearchTerm: string, page: number, pageSize: number }) => {
  const response = await client.post('/quip/get', object);
  return response.data;
};

export const createQuip = async (object: { keywords: string[], quips: string[], minDuplicateCount: number }) => {
  const response = await client.post('/quip/create', object);
  return response.data;
};

export const importQuips = async (file: File) => {
  const formData = new FormData();
  formData.append('file', file);

  const response = await clientFormData.post('/quip/import', formData);
  return response.data;
};

export const exportQuips = async (object: { keywordSearchTerm: string, quipSearchTerm: string }) => {
  const response = await client.post('/quip/export', object, {
    responseType: 'blob',
  });
  return response.data;
}

export const updateQuips = async (object: { id: number, list: Item[] }) => {
  const response = await client.post('/quip/update', object);
  return response.data;
}

export const removeQuip = async (id: number) => {
  const response = await client.delete(`/quip/${id}`);
  return response.data;
};

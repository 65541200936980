import React, { ChangeEvent, FormEvent, useRef, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';

interface Props {
  title?: string;
  show: boolean;
  onClose: () => void;
  handleFileUpload: (file: File) => Promise<any>;
}

export const ModalImport: React.FC<Props> = ({ title, show, onClose, handleFileUpload }) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isDragging, setIsDragging] = useState(false);

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (!files) {
      return;
    }

    await setSelectedFile(files[0]);
  };

  const handleUpload = () => {;
    if (selectedFile) {
      handleFileUpload(selectedFile);
      handleClose();
    } else {
      alert('Please select a file before uploading.');
    }
  };

  const handleClose = () => {
    setSelectedFile(null);
    onClose();
  }

  const handleDragOver = (e: any) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e: any) => {
    e.preventDefault();
    setIsDragging(false);
    const file = e.dataTransfer.files[0];
    setSelectedFile(file);
    if (handleFileUpload) handleFileUpload(file);
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      style={{ zIndex: 99999 }}
    >
      <Modal.Header closeButton>
        <Modal.Title>{title || 'Upload File'}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <label
          className={`w-100 border rounded p-5 text-center ${
            isDragging ? 'bg-light' : ''
          }`}
          style={{
            borderStyle: 'dashed',
            cursor: 'pointer',
            position: 'relative',
          }}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <div className='mb-2'>
            <i className='bi bi-upload' />
          </div>
          <p>
            <strong>Drag & Drop</strong> or{' '}
            <span className='text-primary'>Choose file</span> to upload
          </p>
          <small className='text-muted'>CSV</small>
          <Form.Control
            type='file'
            accept='.csv'
            className='d-none'
            onChange={handleFileChange}
          />
        </label>
        {selectedFile && (
          <div className='mt-3 text-center'>
            <span className='text-success'>
              Selected file: <strong>{selectedFile.name}</strong>
            </span>
          </div>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button variant='secondary' onClick={handleClose}>
          Cancel
        </Button>
        <Button variant='primary' onClick={() => handleUpload()}>
          Upload
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
